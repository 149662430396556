import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Page,
  Text,
  Image,
  Document,
  StyleSheet,
  View,
} from "@react-pdf/renderer";
import headerImage from "../../assets/images/header.png";
import footerImage from "../../assets/images/footer.png";
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 20,
    backgroundColor: "#ffffff",
  },
  section: {
    marginBottom: 20,
  },
  sectionbottm: {
    marginTop: 35,
  },
  headerContainer: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    marginBottom: 10,
  },

  headerImage: {
    width: "100%", // Set width to 100%
    height: "auto", // Maintain aspect ratio
  },
  headerText: {
    textAlign: "center",
    fontSize: 14, // Reduced font size to 14
    fontWeight: "bold",
    color: "#003366", // Dark blue
    marginTop: 2,
    marginBottom: 5,
  },
  addressText: {
    fontSize: 8, // Reduced font size to 10
    fontWeight: "bold",
    marginBottom: 5,
    color: "#333333", // Dark gray
  },
  subHeader: {
    fontSize: 8, // Reduced font size to 10
    fontWeight: "bold",
    marginBottom: 5,
    color: "#009688", // Teal
  },
  subHeaderright: {
    textAlign: "right",
    fontSize: 8, // Reduced font size to 10
    fontWeight: "bold",
    marginBottom: 5,
    color: "#009688", // Teal
  },
  subHeadercenter: {
    textAlign: "center",
    fontSize: 8, // Reduced font size to 10
    fontWeight: "bold",
    marginBottom: 5,
    color: "#009688", // Teal
  },
  item: {
    fontSize: 8,
    marginBottom: 5,
  },
  itemSubtotal: {
    fontSize: 8,
    marginBottom: 5,
    marginTop: 5,
  },
  itemRight: {
    textAlign: "right",
  },
  itemCenter: {
    textAlign: "center",
  },
  itemName: {
    flexGrow: 1,
    fontSize: 8, // Reduced font size to 10
    color: "#333333", // Dark gray
  },
  itemQuantity: {
    marginRight: 10,
    fontSize: 8, // Reduced font size to 10
    color: "#333333", // Dark gray
  },
  itemPrice: {
    width: 80,
    textAlign: "right",
    fontSize: 8, // Reduced font size to 10
    color: "#333333", // Dark gray
  },
  line: {
    borderBottom: "1px solid black",
    paddingBottom: 10,
  },
  total: {
    marginTop: 10,
    fontSize: 8,
    borderTop: "1px solid #FFFF",
    paddingTop: 10,
    textAlign: "left",
    color: "#009688", // Teal
  },
  footerContainer: {
    width: "100%",
    position: "absolute",
    bottom: 20,
    left: 20,
  },
  footerImage: {
    width: "100%", // Set width to 100%
    height: "auto", // Maintain aspect ratio
  },
});

const PDFFile = ({ data }) => {
  return (
    <Document id="table-to-export">
      <Page size="A4" style={styles.page}>
        {/* Header */}
        <View style={styles.headerContainer}>
          <Image src={headerImage} style={styles.headerImage} />
        </View>
        <Text style={styles.headerText}>PURCHASE ORDER</Text>
        {/* divide two section */}
        <View style={styles.section}>
          <View style={{ flexDirection: "row" }}>
            <View style={{ flex: 1 }}>
              <Text style={styles.subHeader}>SUPPLIER</Text>
              <Text style={styles.subHeader}>Prime Guard Oman</Text>
              <Text style={styles.subHeader}>Sandan Instrial City</Text>
              <Text style={styles.subHeader}>Office no 28</Text>
              <Text style={styles.subHeader}>unit 15 Oman</Text>
              <View style={{ flexDirection: "row" }}>
                <Text style={styles.subHeader}>Vendor Name</Text>
                <Text style={styles.subHeader}>:</Text>
                <Text style={styles.addressText}>{data?.supplierName}</Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text style={styles.subHeader}>VAT NO</Text>
                <Text style={styles.subHeader}>:</Text>
                <Text style={styles.addressText}></Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text style={styles.subHeader}>Sup Quote No</Text>
                <Text style={styles.subHeader}>:</Text>
                <Text style={styles.addressText}>PGS/JN/24/1966</Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text style={styles.subHeader}>Quote Date</Text>
                <Text style={styles.subHeader}>:</Text>
                <Text style={styles.addressText}>19-Feb-24</Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text style={styles.subHeader}>Quote Valid Till</Text>
                <Text style={styles.subHeader}>:</Text>
                <Text style={styles.addressText}>19-Feb-24</Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text style={styles.subHeader}>Shipping Via</Text>
                <Text style={styles.subHeader}>:</Text>
                <Text style={styles.addressText}>None</Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text style={styles.subHeader}>Delivery Location</Text>
                <Text style={styles.subHeader}>:</Text>
                <Text style={styles.addressText}></Text>
              </View>
            </View>
            <View style={{ flex: 1 }}>
              <View style={{ flexDirection: "row" }}>
                <Text style={styles.subHeader}>PO NO:</Text>
                <Text style={styles.subHeader}>:</Text>
                <Text style={styles.addressText}>{data?.poNumber}</Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text style={styles.subHeader}>Ref</Text>
                <Text style={styles.subHeader}>:</Text>
                <Text style={styles.addressText}>ALM</Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text style={styles.subHeader}>Shipping Terms</Text>
                <Text style={styles.subHeader}>:</Text>
                <Text style={styles.addressText}>None</Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text style={styles.subHeader}>Payment Mode</Text>
                <Text style={styles.subHeader}>:</Text>
                <Text style={styles.addressText}>Letter of Credit</Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text style={styles.subHeader}>Payment Terms</Text>
                <Text style={styles.subHeader}>:</Text>
                <Text style={styles.addressText}>120 Days</Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text style={styles.subHeader}>Country of Origin</Text>
                <Text style={styles.subHeader}>:</Text>
                <Text style={styles.addressText}></Text>
              </View>
            </View>
            <View style={{ flex: 1 }}>
              <View style={{ flexDirection: "row" }}>
                <Text style={styles.subHeader}>Date:</Text>
                <Text style={styles.addressText}>
                  {moment(data?.poDate).format("DD/MM/YYYY")}
                </Text>
              </View>
            </View>
          </View>
          <View>
            {/* Repeat similar structure for other rows */}

            <View style={styles.subHeader}>
              Perform Invoice required against our LPO - Production will be
              against PI confirmation
            </View>
          </View>
        </View>

        <View style={styles.section}>
          <View style={{ flexDirection: "row" }}>
            <View style={{ flex: 1 }}>
              <Text style={styles.subHeader}>SL.NO</Text>
            </View>
            <View style={{ flex: 3 }}>
              <Text style={styles.subHeader}>Code</Text>
            </View>
            <View style={{ flex: 7 }}>
              <Text style={styles.subHeader}>Description</Text>
            </View>
            <View style={{ flex: 1 }}>
              <Text style={styles.subHeadercenter}>Qty</Text>
            </View>
            <View style={{ flex: 1 }}>
              <Text style={styles.subHeader}>Unit</Text>
            </View>
            <View style={{ flex: 3 }}>
              <Text style={styles.subHeaderright}>Amount</Text>
            </View>
          </View>
          <View class="col-sm-12 col-xs-12 table-responsive">
            <View style={styles.line}>
              {data?.pOdetailModel?.map((item, index) => (
                <View key={index} style={{ flexDirection: "row" }}>
                  <View style={{ flex: 1 }}>
                    <Text style={styles.item}>{index + 1}</Text>
                  </View>
                  <View style={{ flex: 3 }}>
                    <Text style={styles.item}>{item.productCode}</Text>
                  </View>
                  <View style={{ flex: 7 }}>
                    <Text style={styles.item}>{item.productName}</Text>
                  </View>
                  <View style={{ flex: 1 }}>
                    <Text style={[styles.item, styles.itemCenter]}>
                      {item.prodQty}
                    </Text>
                  </View>
                  <View style={{ flex: 1 }}>
                    <Text style={styles.item}>{item.unit}</Text>
                  </View>
                  <View style={{ flex: 3 }}>
                    <Text style={[styles.item, styles.itemRight]}>
                      {item.prodPrice.toFixed(2)}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
            <View style={{ flexDirection: "row" }}>
              <View style={{ flex: 11 }} />
              <View style={{ flex: 3 }}>
                <Text style={[styles.itemSubtotal]}>Sub Total</Text>
              </View>
              <View style={{ flex: 3 }}>
                <Text style={[styles.itemSubtotal, styles.itemRight]}>
                  {data?.poTotal?.toFixed(2)}
                </Text>
              </View>
            </View>
            <View style={{ flexDirection: "row" }}>
              <View style={{ flex: 11 }} />
              <View style={{ flex: 3 }}>
                <Text style={[styles.item]}>Other Charges</Text>
              </View>
              <View style={{ flex: 3 }}>
                <Text style={[styles.item, styles.itemRight]}>{0.0}</Text>
              </View>
            </View>
            <View style={{ flexDirection: "row" }}>
              <View style={{ flex: 11 }} />
              <View style={{ flex: 3 }}>
                <Text style={[styles.total]}>Grand Total</Text>
              </View>
              <View style={{ flex: 3 }}>
                <Text style={[styles.total, styles.itemRight]}>
                  {data?.poTotal?.toFixed(2)}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View style={{ flexDirection: "row" }}>
          <View style={{ flex: 1 }}>
            <Text style={styles.subHeader}>Ampount:</Text>
            <Text style={styles.addressText}>
              Oman Riyals Eight Thousand Eight Hundred Twenty Only
            </Text>
          </View>
        </View>
        <View style={{ flexDirection: "row" }}>
          <View style={{ flex: 1 }}>
            <Text style={styles.subHeader}>Document Note:</Text>
          </View>
          <View style={{ flex: 1 }}>
            <Text style={styles.subHeader}>Terms & Conditions</Text>
          </View>
        </View>

        {/* Side table */}
     
        <View style={styles.section}>
          <View style={{ flexDirection: "row" }}>
            <View style={{ flex: 1 }}>
            <View style={styles.section}>
            <View style={{ flexDirection: "row" }}>
              <View style={{ flex: 3 }}>
                <Text style={styles.subHeader}>Code</Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.subHeadercenter}>Qty</Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.subHeader}>Unit</Text>
              </View>
              <View style={{ flex: 3 }}>
                <Text style={styles.subHeaderright}>Amount</Text>
              </View>
            </View>
            <View class="col-sm-12 col-xs-12 table-responsive">
              <View style={styles.line}>
                {data?.pOdetailModel?.map((item, index) => (
                  <View key={index} style={{ flexDirection: "row" }}>
                    <View style={{ flex: 3 }}>
                      <Text style={styles.item}>{item.productCode}</Text>
                    </View>
                    <View style={{ flex: 1 }}>
                      <Text style={[styles.item, styles.itemCenter]}>
                        {item.prodQty}
                      </Text>
                    </View>
                    <View style={{ flex: 1 }}>
                      <Text style={styles.item}>{item.unit}</Text>
                    </View>
                    <View style={{ flex: 3 }}>
                      <Text style={[styles.item, styles.itemRight]}>
                        {item.prodPrice.toFixed(2)}
                      </Text>
                    </View>
                  </View>
                ))}
              </View>
            </View>
          </View>
            </View>
            <View style={{ flex: 1 }}></View>
          </View>
        </View>

        <View style={styles.sectionbottm}>
          <View style={{ flexDirection: "row" }}>
            <View style={{ flex: 1 }}>
              <Text style={styles.subHeader}>Prepared By:</Text>
            </View>
            <View style={{ flex: 1 }}>
              <Text style={styles.subHeader}>Reviewed By:</Text>
            </View>
            <View style={{ flex: 1 }}>
              <Text style={styles.subHeader}>Approved By</Text>
            </View>
            <View style={{ flex: 1 }}>
              <Text style={styles.subHeader}>AuthorisedBy:</Text>
              <Text style={styles.addressText}>{"(General Manager)"}</Text>
            </View>
          </View>
        </View>

        {/* Footer */}
        <View style={styles.footerContainer}>
          <Image src={footerImage} style={styles.footerImage} />
        </View>
      </Page>
    </Document>
  );
};

export default PDFFile;
