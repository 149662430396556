import React, { FC, Fragment, useState, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Button, InputGroup, Form } from 'react-bootstrap';
import 'filepond/dist/filepond.min.css';
import "react-multiple-select-dropdown-lite/dist/index.css";
import axios from 'axios';
import Loader from '../../../utils/Loader';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useNavigate } from 'react-router-dom';
import { Oval } from "react-loader-spinner";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Table } from 'react-bootstrap';
import { toast } from "react-toastify";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import search from "../../../assets/images/search.png";
import edit from "../../../assets/images/edit.svg";
import del from "../../../assets/images/trash.svg";
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';

function CategoryList() {
    const navigate = useNavigate()
    const schema = yup.object().shape({
      projectName: yup.string().required("Department Name required"),
    })
    const {
      register,
      handleSubmit,
      reset,
      setValue,
      clearErrors,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(schema),
    });
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([])
    const [pagination, setPagination] = useState(1);
    const [totalPages, setTotalPages] = useState();
    const pageSize = 20;
    useEffect(() => {
      onSubmit()
    }, [])
  
    const onSubmit = async (value) => {
      setLoading(true)
      axios({
        url: `${process.env.REACT_APP_SERVER_URL}/api/ProductCategory/GetProductCategoryList?categoryName=${value?.categoryName ? value?.categoryName : ''}&Offset=${value?.offNum ? value?.offNum : 0}&RecordCount=${pageSize}`,
        method: 'GET',
      }).then((response) => {
        setLoading(false)
        setData(response.data.data)
        setTotalPages(response.data.totalRecords);
      })
        .catch((error) => {
          setLoading(false)
        })
    };
    const changePage = (value) => {
      let temp = (value - 1) * 20;
      setPagination(temp);
      onSubmit({ offNum: temp });
  };
  const ceil = Math.ceil(totalPages / pageSize);
    const addmore = () => {
      navigate('/add-category')
    }
    const editfunction = (data) => {
      navigate('/edit-category', { state: { id: data } });
    }
    const deletefunction = async (id) => {
      const upload = {
        categoryId: parseInt(id),
      }
      setLoading(true)
      confirmAlert({
        title: 'Confirm to submit',
        message: 'Are you sure to do this.',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              try {
                axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/ProductCategory/DeleteProductCategoryById`, {
                  data: upload,
                }).then((response) => {
                  toast.success(response.data.message);
                  setLoading(false);
                  window.location.reload();
                }).catch((error) => {
                  setLoading(false);
                  console.log(error);
                });
              } catch (error) {
                setLoading(false);
                console.log(error);
              }
            },
          },
          {
            label: 'No',
            onClick: () => alert('Click No'),
          },
        ],
      });
    }
    const handleKeyDown = (e) => {
      // Check if the pressed key is "Enter"
      if (e.key === 'Enter') {
        // Call your submit function here
        onSubmit({ categoryName: e.target.value });
      }
    };
  return (
    <>
    {/* <Loader loading={loading} /> */}
    <div className="col-xl-12">
      <div class="card custom-card">
        <div class="card-header justify-content-between">
          <div class="card-title">
            Categories
          </div>
          <div class="prism-toggle">
            <button class="btn btn-sm btn-primary-light" type='button' onClick={addmore}>Add New<i class="ri-code-line ms-2 d-inline-block align-middle"></i></button>
          </div>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <div class="gridjs-search mt-1 mb-2">
              <input type="search" placeholder="Type a keyword and press enter" name='categoryName' onKeyDown={handleKeyDown}  {...register("categoryName")} aria-label="Type a keyword..." class="gridjs-input gridjs-search-input" />
            </div>
            {loading ?
                  <Skeleton count={5} /> : <table class="gridjs-table">
              <thead>
                <tr>
                  <th className='gridjs-th'>S NO</th>
                  <th className='gridjs-th'>Category Name</th>
                  <th className='gridjs-th'>Action</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((contact, index) => {
                  return (
                    <tr key={index}>
                      <td class="gridjs-td">{index + 1}</td>
                      <td class="gridjs-td">{contact.categoryName}</td>
                      <td class="gridjs-td">
                        <div class="hstack gap-2 fs-15">
                          <a class="btn btn-icon btn-sm btn-light" style={{ marginLeft: 10, marginRight: 10 }} onClick={() => editfunction(contact?.categoryId)} ><img src={edit} /></a>
                          <a class="btn btn-icon btn-sm btn-light" style={{ marginLeft: 10, marginRight: 10 }} onClick={() => deletefunction(contact?.categoryId)}><img src={del} /></a>
                        </div>
                      </td>
                    </tr>
                  )
                })}

              </tbody>
            </table>}
            <div class="gridjs-footer"><div class="gridjs-pagination">
              <div class="gridjs-pages">
                <ResponsivePagination
                  current={pagination}
                  total={ceil}
                  onPageChange={(value) => changePage(value)}
                />
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}

export default CategoryList
