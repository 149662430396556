import React, { FC, Fragment, useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { Card, Col, Row } from "react-bootstrap";
import { Button, InputGroup, Form } from "react-bootstrap";
import "filepond/dist/filepond.min.css";
import "react-multiple-select-dropdown-lite/dist/index.css";
import axios from "axios";
import ReactPDF from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import Loader from "../../utils/Loader";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate } from "react-router-dom";
import { Oval } from "react-loader-spinner";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Table } from "react-bootstrap";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import search from "../../assets/images/search.png";
import edit from "../../assets/images/edit.svg";
import del from "../../assets/images/trash.svg";
import apprv from "../../assets/images/approval.png";
import print from "../../assets/images/print.png";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import PDFViewerComponent from "../Reports/PDFViewer"; // Import the PDFViewerComponent
import POprintout from "../Reports/PO_printout";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PDFFile from "./PDFFile";
function POlist() {
  const navigate = useNavigate();
  const schema = yup.object().shape({});
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [apidata, setApiData] = useState([]);
  const [pagination, setPagination] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const pageSize = 20;
  const [showPDFViewer, setShowPDFViewer] = useState(false);

  const handlePdfButtonClick = (data) => {
    console.log(data);
    setShowPDFViewer(true);
    getUpdatedDataForPO(data);
  };
  const getUpdatedDataForPO = async (data) => {
    //  console.log(data);
    
  };

  const openPDFInNewWindow = () => {
    const pdfData = <POprintout data={apidata} />;
    console.log("open", apidata);

    <PDFViewerComponent document={pdfData} />;
    const newWindow = window.open("", "_blank");
    newWindow.document.write('<div id="root"></div>'); // Create a placeholder for React rendering

    const container = newWindow.document.getElementById("root");
    const root = createRoot(container);
    root.render(<PDFViewerComponent document={pdfData} />);
  };

  useEffect(() => {
    onSubmit();
  }, []);

  useEffect(() => {
    // if (isLoading) {
    // openPDFInNewWindow();
    // }
  }, [apidata, isLoading]);

  const onSubmit = async (value) => {
    console.log("onSubmit");
    setLoading(true);
    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/api/PO/GetPOList?poNumber=${
        value?.poNumber ? value?.poNumber : ""
      }&Offset=${value?.offNum ? value?.offNum : 0}&RecordCount=${pageSize}`,
      method: "GET",
    })
      .then((response) => {
        setLoading(false);
        setData(response.data.data);
        setTotalPages(response.data.totalRecords);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const changePage = (value) => {
    let temp = (value - 1) * 20;
    setPagination(temp);
    onSubmit({ offNum: temp });
  };
  const ceil = Math.ceil(totalPages / pageSize);
  const addmore = () => {
    navigate("/PO");
  };
  const editfunction = (data) => {
    navigate("/edit-PO", { state: { id: data } });
  };
  const deletefunction = async (id) => {
    const upload = {
      POId: parseInt(id),
    };
    setLoading(true);
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            try {
              axios
                .delete(
                  `${process.env.REACT_APP_SERVER_URL}/api/MRCategory/DeleteMRCategoryById1`,
                  {
                    data: upload,
                  }
                )
                .then((response) => {
                  toast.success(response.data.message);
                  setLoading(false);
                  window.location.reload();
                })
                .catch((error) => {
                  setLoading(false);
                  console.log(error);
                });
            } catch (error) {
              setLoading(false);
              console.log(error);
            }
          },
        },
        {
          label: "No",
          onClick: () => alert("Click No"),
        },
      ],
    });
  };
  const handleKeyDown = (e) => {
    // Check if the pressed key is "Enter"
    if (e.key === "Enter") {
      // Call your submit function here
      onSubmit({ poNumber: e.target.value });
    }
  };
  const approvenav = (data) => {
    navigate("/approve-po", { state: { id: data } });
  };
  const handlePdf = async (data) => {
    setShowPDFViewer(true);
    await axios({
      url: `${
        process.env.REACT_APP_SERVER_URL
      }/api/PO/GetPOById?poid=${parseInt(data)}`,
      method: "GET",
    })
      .then((res) => {
        setIsLoading(true);
        console.log("api", res);
        setApiData(res.data.data);
        console.log("apidata", apidata);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const handleUploadfile=async (data)=> {
    setShowPDFViewer(true);
    await axios({
      url: `${
        process.env.REACT_APP_SERVER_URL
      }/api/PO/GetPOById?poid=${parseInt(data)}`,
      method: "GET",
    })
      .then((res) => {
        setIsLoading(true);
        console.log("api", res.data.data);
        setApiData(res.data.data);
        console.log("apidata", apidata);
      })
      .catch((error) => {
        setLoading(false);
      });
  }
  return (
    <>
      <div class="col-xl-12">
        <div class="card custom-card">
          <div class="card-header justify-content-between">
            <div class="card-title">PO List</div>
            <div class="prism-toggle">
              <button
                class="btn btn-sm btn-primary-light"
                type="button"
                onClick={addmore}
              >
                Add New
                <i class="ri-code-line ms-2 d-inline-block align-middle"></i>
              </button>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <div class="gridjs-search mt-1 mb-2">
                <input
                  type="search"
                  placeholder="Type a keyword and press enter"
                  name="poNumber"
                  onKeyDown={handleKeyDown}
                  {...register("poNumber")}
                  aria-label="Type a keyword..."
                  class="gridjs-input gridjs-search-input"
                />
              </div>
              {loading ? (
                <Skeleton count={5} />
              ) : (
                <table class="gridjs-table">
                  <thead>
                    <tr>
                      <th className="gridjs-th">S NO</th>
                      <th className="gridjs-th">PO Number</th>
                      <th className="gridjs-th">PO Date</th>
                      <th className="gridjs-th">PO Total</th>
                      <th className="gridjs-th">Supplier Name</th>
                      <th className="gridjs-th">Project Name</th>
                      <th className="gridjs-th">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((contact, index) => {
                      return (
                        <tr key={index}>
                          <td class="gridjs-td">{index + 1}</td>
                          <td class="gridjs-td">{contact.poNumber}</td>
                          <td class="gridjs-td">
                            {contact.poDate?.split("T")[0]}
                          </td>
                          <td class="gridjs-td">{contact.poTotal}</td>
                          <td class="gridjs-td">{contact.supplierName}</td>
                          <td class="gridjs-td">{contact.projectName}</td>
                          <td class="gridjs-td">
                            <div class="hstack gap-2 fs-15">
                              <a
                                class="btn btn-icon btn-sm btn-light"
                                data-tooltip-id="Edit"
                                data-tooltip-content="Edit PO"
                                style={{ marginLeft: 10, marginRight: 10 }}
                                onClick={() => editfunction(contact?.poId)}
                              >
                                <img src={edit} />
                              </a>
                              <a
                                class="btn btn-icon btn-sm btn-light"
                                data-tooltip-id="Delete"
                                data-tooltip-content="Delete PO"
                                style={{ marginLeft: 10, marginRight: 10 }}
                                onClick={() => deletefunction(contact?.poId)}
                              >
                                <img src={del} />
                              </a>
                              <a
                                class="btn btn-icon btn-sm btn-light"
                                data-tooltip-id="Approve"
                                data-tooltip-content="Approve PO"
                                style={{ marginLeft: 10, marginRight: 10 }}
                                onClick={() => approvenav(contact?.poId)}
                              >
                                <img src={apprv} />
                              </a>
                              <a
                                class="btn btn-icon btn-sm btn-light"
                                data-tooltip-id="Print"
                                data-tooltip-content="Print PO"
                                style={{ marginLeft: 10, marginRight: 10 }}
                                onClick={() => handleUploadfile(contact?.poId)}
                              >
                                <PDFDownloadLink
                                  document={<PDFFile data={apidata} />}
                                  filename="FORM"
                                >
                                  <img src={print} />
                                </PDFDownloadLink>
                              </a>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
              <div class="gridjs-footer">
                <div class="gridjs-pagination">
                  <div class="gridjs-pages">
                    <ResponsivePagination
                      current={pagination}
                      total={ceil}
                      onPageChange={(value) => changePage(value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Tooltip id="Edit" />
      <Tooltip id="Delete" />
      <Tooltip id="Approve" />
    </>
  );
}

export default POlist;
