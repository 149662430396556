import React, { useState, useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";
import edit from "../../assets/images/edit.svg";
import del from "../../assets/images/trash.svg";
import plus from "../../assets/images/plus.png";
import { confirmAlert } from 'react-confirm-alert';
import axios from 'axios';
import { Card, Col, Row } from 'react-bootstrap';
import { Button, InputGroup, Form } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';
import { useNavigate } from 'react-router-dom';
import FloatGroup from 'react-float-button';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function AddMRInit() {
    const navigate = useNavigate()
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = 1;
    const schema = yup.object().shape({
        designationName: yup.string().required("Designation Name required"),
    })
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        clearErrors,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const [mriDate, setMRiDate] = useState(new Date());
    const [mrinum, setNum] = useState('');
    const [mrinotes, setNotes] = useState('');
    const [locationList, setLocationList] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [selectedProject, setSelectedProject] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState([]);

    const handleSelectproject = (selectedOption) => {
        setSelectedProject(selectedOption);
    };
    const handleSelectLocation = (selectedOption) => {
        setSelectedLocation(selectedOption);
        console.log(selectedOption);
    };


    const mriNumberADD = (e) => {
        setNum(e.target.value)
    }

    const mriNotesADD = (e) => {
        setNotes(e.target.value)
    }

    useEffect(() => {
        projectlist()
        locationlist()
    }, [])



    const projectlist = async (value) => {
        axios({
            url: `${process.env.REACT_APP_SERVER_URL}/api/Project/GetProjectList`,
            method: 'GET',
        }).then((response) => {
            const formattedData = response.data.data.map(item => ({
                label: item.projectName,
                value: item.projectId
            }));
            setProjectList(formattedData)
        })
            .catch((error) => {
            })
    };
    const locationlist = async (value) => {
        axios({
            url: `${process.env.REACT_APP_SERVER_URL}/api/Location/GetLocationList`,
            method: 'GET',
        }).then((response) => {
            const formattedData = response.data.data.map(item => ({
                label: item.locationName,
                value: item.locationId
            }));
            setLocationList(formattedData)
        })
            .catch((error) => {
            })
    };
    const AddMRInit = () => {
        axios({
            url: `${process.env.REACT_APP_SERVER_URL}/api/MRInit/CreateMRInit`,
            method: 'POST',
            data: {
                MRIDate: mriDate,
                MRINumber: mrinum,
                mriProjectId: selectedProject?.value,
                mriLocationId: selectedLocation?.value,
                mriNotes: mrinotes
            },
        }
        ).then((response) => {
            // toast.success("MR crated successfully ")
            navigate('/MRInit-list')
        })
            .catch((error) => {
                toast.error("something went wrong")
                console.log(error);
            })
    }
    const [inde, setInde] = useState(0)


    return (
        <>
            <div className="container-fluid">
                <div className="row" >
                    <div className="col-xxl-12">
                        <div className="card custom-card" id="cart-container-delete">
                            <div className="card-header">
                                <div className="card-title">
                                    Add MRInit
                                </div>
                            </div>
                            <div className="p-3">
                                <Row>
                                <Form.Group as={Col} md="2" controlId="validationCustom01">
                                        <Form.Label>MRInit Number</Form.Label>
                                        <div style={{ border: '1px solid #f3f3f3', width: '100%', padding: 8 }}>
                                            <input type="text" readOnly placeholder="Auto" name="mriNumber" onChange={mriNumberADD} />
                                        </div>
                                    </Form.Group>
                                    <Form.Group as={Col} md="2" controlId="validationCustom02">
                                        <Form.Label>MRInit Date</Form.Label>
                                        <div style={{ border: '1px solid #f3f3f3', width: '100%', padding: 8 }}>
                                            <DatePicker selected={mriDate} onChange={(date) => setMRiDate(date)} />
                                        </div>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                        <Form.Label>Project</Form.Label>
                                        <Select
                                            value={selectedProject}
                                            onChange={handleSelectproject}
                                            options={projectList}
                                            isSearchable
                                            placeholder="Search Project"
                                        />
                                        <p className="errorMsg pl-3 mt-2">{errors.designationName ? errors.designationName.message : ''}</p>
                                    </Form.Group>

                                    <Form.Group as={Col} md="4" controlId="validationCustom02">
                                        <Form.Label>Location</Form.Label>
                                        <Select
                                            value={selectedLocation}
                                            onChange={handleSelectLocation}
                                            options={locationList}
                                            isSearchable
                                            placeholder="Search Location"
                                        />
                                        <p className="errorMsg pl-3 mt-2">{errors.designationName ? errors.designationName.message : ''}</p>
                                    </Form.Group>
                                </Row>
                            </div>
                           
                            <div className="p-3">
                                <Row>

                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Form.Label>Notes</Form.Label>
                                        <div style={{ border: '1px solid #f3f3f3', width: '100%', padding: 8 }}>
                                        <textarea class="form-control" id="text-area" onChange={mriNotesADD}  rows="5"></textarea>
                                        
                                        </div>
                                    </Form.Group>


                                </Row>
                            </div>

                            <div className="row card-body">
                                <div className="col-4"></div>
                                <div className="col-4 p-5">
                                    <Button style={{ width: '100%' }} className="mt-3" onClick={AddMRInit}>Save</Button>
                                </div>
                                <div className="col-4"></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <ToastContainer />
        </>
    )
}

export default AddMRInit
