import React, { FC, Fragment, useState } from "react";
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import mark from '../../assets/images/header.png';

import { PDFViewer } from "@react-pdf/renderer";
import { Card, Col, Row } from "react-bootstrap";
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});
const TAX_RATE = 0.07;

function ccyFormat(num) {
  return `${num.toFixed(2)}`;
}

function priceRow(qty, unit) {
  return qty * unit;
}

function createRow(desc, qty, unit) {
  const price = priceRow(qty, unit);
  return { desc, qty, unit, price };
}

function subtotal(items) {
  return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
}

const rows = [
  createRow("Paperclips (Box)", 100, 1.15),
  createRow("Paper (Case)", 10, 45.99),
  createRow("Waste Basket", 2, 17.99),
];

const invoiceSubtotal = subtotal(rows);
const invoiceTaxes = TAX_RATE * invoiceSubtotal;
const invoiceTotal = invoiceTaxes + invoiceSubtotal;
const POprintout = ({ data }) => {
  return (
    <Fragment>
      <div className="container">
        <Row>
          <div className="col mx-auto mt-2">
            <div className="row justify-content-center align-items-center">
              <Row>
                {" "}
                <Col md={6} className="invoice-header-logo-left">
                  <div>
                    <img src={mark} width={200} />
                  </div>
                </Col>
                <Col md={6} className="invoice-header-logo-right">
                  <div>
                    <img src={mark} width={200} />
                  </div>
                </Col>
              </Row>
              <h1 className="invoice-header">PURCHASE ORDER</h1>
              <Row>
                <Col md={6}>
                  <Row>
                    <Col md={8}>
                      <Row>
                        <Col md={7}>
                          <div className="supplier-content">
                            <h3>SUPPLIER</h3>
                            <h3>Prime Guard Oman</h3>
                            <p className="font-weight-normal">
                              Sandan Instrial City
                            </p>
                            <p className="font-weight-normal">Office no 28</p>
                            <p className="font-weight-normal">unit 15 Oman</p>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={5} className="font-weight-bold">
                          VAT NO
                        </Col>
                        <Col md={1} className="font-weight-bold">
                          :
                        </Col>
                        <Col md={6} className="font-weight-normal"></Col>
                      </Row>
                      <Row>
                        <Col md={5} className="font-weight-bold">
                          Sup Quote No
                        </Col>
                        <Col md={1} className="font-weight-bold">
                          :
                        </Col>
                        <Col md={6} className="font-weight-normal">
                          PGS/JN/24/1966
                        </Col>
                      </Row>
                      <Row>
                        <Col md={5} className="font-weight-bold">
                          Quote Date
                        </Col>
                        <Col md={1} className="font-weight-bold">
                          :
                        </Col>
                        <Col md={6} className="font-weight-normal">
                          19-Feb-24
                        </Col>
                      </Row>
                      <Row>
                        <Col md={5} className="font-weight-bold">
                          Quote Valid Till
                        </Col>
                        <Col md={1} className="font-weight-bold">
                          :
                        </Col>
                        <Col md={6} className="font-weight-normal">
                          29-Feb-24
                        </Col>
                      </Row>
                      <Row>
                        <Col md={5} className="font-weight-bold">
                          Shipping Via
                        </Col>
                        <Col md={1} className="font-weight-bold">
                          :
                        </Col>
                        <Col md={6} className="font-weight-normal"></Col>
                      </Row>
                      <Row>
                        <Col md={5} className="font-weight-bold">
                          Delivery Location
                        </Col>
                        <Col md={1} className="font-weight-bold">
                          :
                        </Col>
                        <Col md={6} className="font-weight-normal"></Col>
                      </Row>
                    </Col>
                    <Col md={4}></Col>
                  </Row>
                </Col>
                <Col md={6}>
                  <Row>
                    <Col md={7}>
                      <Row>
                        <Col md={5} className="font-weight-bold">
                          PO NO
                        </Col>
                        <Col md={1} className="font-weight-bold">
                          :
                        </Col>
                        <Col md={6} className="font-weight-normal">
                          24000135/NL
                        </Col>
                      </Row>
                      <Row>
                        <Col md={5} className="font-weight-bold">
                          Supplier Code
                        </Col>
                        <Col md={1} className="font-weight-bold">
                          :
                        </Col>
                        <Col md={6} className="font-weight-normal">
                          CPR18
                        </Col>
                      </Row>
                      <Row>
                        <Col md={5} className="font-weight-bold">
                          Ref
                        </Col>
                        <Col md={1} className="font-weight-bold">
                          :
                        </Col>
                        <Col md={6} className="font-weight-normal">
                          ALM
                        </Col>
                      </Row>
                      <Row>
                        <Col md={5} className="font-weight-bold">
                          Payment Mode
                        </Col>
                        <Col md={1} className="font-weight-bold">
                          :
                        </Col>
                        <Col md={4} className="font-weight-normal">
                          Letter of Credit
                        </Col>
                      </Row>
                      <Row>
                        <Col md={5} className="font-weight-bold">
                          Payment Terms
                        </Col>
                        <Col md={1} className="font-weight-bold">
                          :
                        </Col>
                        <Col md={4} className="font-weight-normal"></Col>
                      </Row>
                      <Row>
                        <Col md={5} className="font-weight-bold">
                          Country of Origin
                        </Col>
                        <Col md={1} className="font-weight-bold">
                          :
                        </Col>
                        <Col md={4} className="font-weight-normal"></Col>
                      </Row>
                      <Row>
                        <Col md={5} className="font-weight-bold">
                          Remarks
                        </Col>
                      </Row>
                    </Col>
                    <Col md={5} className="d-flex">

                      <div className="font-weight-bold">Date:</div>
                      <div className="font-weight-normal">19-Feb-24</div>

                    </Col>
                    <Row>
                      <Col md={12}>
                        <p className="font-weight-normal">
                          Perform Invoice required against our LPO - Production
                          will be against PI confirmation
                        </p>
                      </Col>
                    </Row>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-2 mb-2">
                <Col id="price-table">
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 600 }} aria-label="spanning table">
                      <TableHead>
                        <TableRow>
                          <TableCell>SIno</TableCell>
                          <TableCell>Job No.</TableCell>
                          <TableCell>MR.No.</TableCell>
                          <TableCell>Required Date</TableCell>
                          <TableCell>Item Name</TableCell>
                          <TableCell>UOM</TableCell>
                          <TableCell align="right">Qty.</TableCell>
                          <TableCell align="right">Unit Price</TableCell>
                          <TableCell align="right">Discount</TableCell>
                          <TableCell align="right">VAT</TableCell>
                          <TableCell align="right">Net Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>1</TableCell>
                          <TableCell>A1261003120</TableCell>
                          <TableCell>66601</TableCell>
                          <TableCell>28-Feb-24</TableCell>
                          <TableCell>
                            Fire rated doors window -Approximated Size 1250*
                            1900- Fix Window (Final Production As per DWNG) with
                            double glazed(30 MM D/G Unit
                          </TableCell>
                          <TableCell>NOS</TableCell>
                          <TableCell align="right">6.00</TableCell>
                          <TableCell align="right">1,400,000</TableCell>
                          <TableCell align="right">0.00</TableCell>
                          <TableCell align="right">420.000</TableCell>
                          <TableCell align="right">8.820.000</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell rowSpan={8} />
                          <TableCell colSpan={8} align="right">
                            Total
                          </TableCell>
                          <TableCell align="right">
                            {ccyFormat(invoiceSubtotal)}
                          </TableCell>
                          <TableCell align="right">
                            {ccyFormat(invoiceSubtotal)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={8} align="right">
                            Discount
                          </TableCell>
                          <TableCell align="right"></TableCell>
                          <TableCell align="right">{"0.00"}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={8} align="right">
                            Net Amount
                          </TableCell>
                          <TableCell align="right"></TableCell>
                          <TableCell align="right">
                            {ccyFormat(invoiceTotal)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Col>
              </Row>
              <Row>
                <Col md={6} className="font-weight-bold d-flex">
                  <div className="font-weight-bold">Amount:</div>
                  <div className="font-weight-bold">Oman Riyals Eight Thousand Eight Hundred Twenty Only</div>

                </Col>
              </Row>
              <Row>
                <Col md={6} className="font-weight-bold">
                  DOCUMENT NOTE:
                </Col>
                <Col md={6} className="font-weight-bold">
                  Terms & Conditions
                </Col>
              </Row>
              <Row>
                <Col md={6} id="price-table">
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 300 }} aria-label="spanning table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Project</TableCell>
                          <TableCell>Curr</TableCell>
                          <TableCell align="right">Vat</TableCell>
                          <TableCell align="right">Net Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>{"A126100"}</TableCell>
                          <TableCell>{"OMR"}</TableCell>
                          <TableCell align="right">{"420.00"}</TableCell>
                          <TableCell align="right">{"8820.00"}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell rowSpan={8} />
                          <TableCell colSpan={8} align="right">
                            {"8820.00"}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col md={3} className="font-weight-bold pt-9">
                  Prepared By:
                </Col>
                <Col md={3} className="font-weight-bold pt-9">
                  Reviewd By:
                </Col>
                <Col md={3} className="font-weight-bold pt-9">
                  Approved By:
                </Col>
                <Col
                  md={3}
                  className="d-flex font-weight-bold pt-9"
                  style={{ flexDirection: "column" }}
                >
                  <div>Authorised By:</div>
                  <div>{"Gernal Manager"}</div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="footer-content">
                    <h3>VAIN:OM110001733X | TAX CARD NO:8197881 | TIN:3969</h3>
                    <h3>
                      المهندسين والمقاولين الميكانيكيين والكهربائيين.تصنيع
                      الألمنيوم والصلب.
                    </h3>
                    <h3>
                      Mechanical & Electrical Engineers and
                      Contractors.Aluminium & Steel Fabrication.
                    </h3>
                  </div>
                </Col>
              </Row>
              <Row style={{ lineHeight: "5px" }} className="mt-2">
                <Col md={3}>
                  <div className="footer-bottom">
                    <p>P.O Box:2033,Ruwi</p>
                    <p>Oostal Code:112,Sultanate of Oman</p>
                    <p>Tel:+968 24447060,Fax:_968 24447061</p>
                    <p>C.R.NO:1/31710/5</p>
                    <p>E-mail:airmech@airmechoman.com</p>
                  </div>
                </Col>
                <Col md={6} className="footer-logo">
                  <div className="d-flex footer-logo-inner">
                    <img src={mark} width={100} />
                    <img src={mark} width={100} />
                    <img src={mark} width={100} />
                    <img src={mark} width={100} />
                  </div>
                </Col>
                <Col md={3}>
                  <div className="footer-bottom-right">
                    <p>صندوق بريد: 2033,روي</p>
                    <p>Oostal Code:112,Sultanate of Oman</p>
                    <p>Tel:+968 24447060,Fax:_968 24447061</p>
                    <p>C.R.NO:1/31710/5</p>
                    <p>E-mail:airmech@airmechoman.com</p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Row>
      </div>
    </Fragment>
  );
};
export default POprintout;
