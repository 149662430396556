import { BrowserRouter , Routes, Route } from 'react-router-dom';
import React, { Fragment } from 'react';
import Loader from './utils/Loader';
import Login from './pages/Login';
import './index.scss';
import Register from './pages/Register';
import Firebaselayout from './layouts/firebase/firebaseauthentication/firebaselayout';
import { RouterData } from './commoncomponents/routingdata';
import Main from './Main';
function App() {
  return (
    <Fragment>
      <BrowserRouter>
        <React.Suspense fallback={<Loader />}>
          <Routes>
          <Route path={'/'} element={<Firebaselayout />}>
          <Route index element={<Login />} />
            <Route path={'/'} element={<Login />} />
            <Route path={'/register'} element={<Register />} />
          </Route>
                {RouterData.map((idx) => (
            <Route path={'/'} element={<Main />} key={Math.random()}>
              <Route path={idx.path} element={idx.element} />
            </Route>
          ))};
          </Routes>
        </React.Suspense>
      </BrowserRouter>
    </Fragment>
  );
}
export default App;
