import React, { useState, useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";
import edit from "../../assets/images/edit.svg";
import del from "../../assets/images/trash.svg";
import plus from "../../assets/images/plus.png";
import { confirmAlert } from 'react-confirm-alert';
import axios from 'axios';
import { Card, Col, Row } from 'react-bootstrap';
import { Button, InputGroup, Form } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';
import { useNavigate } from 'react-router-dom';
import FloatGroup from 'react-float-button';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useSearchParams } from "react-router-dom";

function EditMr() {
  const navigate = useNavigate()
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 1;
  const schema = yup.object().shape({
    designationName: yup.string().required("Designation Name required"),
  })
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [addProductList, setAddProductList] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [startDate1, setStartDate1] = useState(new Date());
  const [mrcity, setMrcity] = useState('');
  const [mrnum, setNum] = useState('');
  const [Qnty, setQnty] = useState(0);
  const [price, setPrice] = useState(0);
  const [units, setUnits] = useState([]);
  const [proData, setProData] = useState([])
  const [mrcategory, setMrcategory] = useState([]);
  const [productList, setProductList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [mrireflist, setMRInitList] = useState([]);
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [selectedMrcategory, setSelectedMrcategory] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectedProject, setSelectedProject] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [selectedMRi, setSelectedMRi] = useState([]);
  const [selectedPrio, setSelectedPrio] = useState([]);
  const handleSelectproject = (selectedOption) => {
    setSelectedProject(selectedOption);
  };
  const handleSelectEmploee = (selectedOption) => {
    setSelectedEmployee(selectedOption);
  };
  const handleSelectMr = (selectedOption) => {
    setSelectedMrcategory(selectedOption);
  };
  const handleSelectMRi= (selectedOption) => {
    setSelectedMRi(selectedOption);
};
  const handleSelectProduct = (selectedOption) => {
    setSelectedProduct(selectedOption);
    onShowUnits(selectedOption?.value)
  };
  const handleSelectUnits = (selectedOption) => {
    console.log(selectedOption);
    setSelectedUnits(selectedOption);
    const result = proData.find(item => item.unitId === selectedOption.value);
    setPrice(result?.productCost)
  };
  const handleSelectPrio = (selectedOption) => {
    setSelectedPrio(selectedOption);
  };  
  const mrNumber = (e) => {
    setNum(e.target.value)
  }
  useEffect(() => {
    
    mrcategorylist()
    productlist()
    projectlist()
    // employeelist()
    mrilist()
    mrdatalistbyid()
  }, [])
  const mrdatalistbyid = async => {
    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/api/MR/GetMRById?mrid=${parseInt(location.state.id)} `,
      method: 'GET',
    }).then((res) => {
      setSelectedProject({
        label: res.data.data.projectName,
        value: res.data.data.mrProjectId
      })
      setSelectedEmployee({
        label: res.data.data.employeeName,
        value: res.data.data.mrEmployeeId
      })
      setSelectedMRi({
        label: res.data.data.mrInitNumber,
        value: res.data.data.mrInitId
      })
      setSelectedMrcategory({
        label: res.data.data.categoryName,
        value: res.data.data.mrCategoryId
      })
      setSelectedPrio({
        label: res.data.data.mrStatus === 0 ? 'Major' : 'Minor',
        value: res.data.data.mrStatus
      })
      setAddProductList(res.data.data.materialRequestDetails)

      setNum(res.data.data.mrNumber)
      setStartDate1(res.data.data.mrDate ? new Date(res.data.data.mrDate) : new Date())
    })
      .catch((error) => {
      })
  }
  const onShowUnits = async (value) => {
    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/api/Product/GetUnitByProduct?ProductId=${value}`,
      method: 'GET',
    }).then((response) => {
      setProData(response.data.data)
      const formattedData = response.data.data.map(item => ({
        label: item.unitCode,
        value: item.unitId
      }));
      setUnits(formattedData)
    })
      .catch((error) => {
      })
  };
  const mrilist = async (value) => {
    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/api/MRInit/GetMRInitList`,
      method: 'GET',
    }).then((response) => {
      const formattedData = response.data.data.map(item => ({
        label: item.mriNumber,
        value: item.mriId
      }));
      setMRInitList(formattedData)
    })
      .catch((error) => {
      })
  };
  const mrcategorylist = async (value) => {
    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/api/MRCategory/GetMRCategoryList`,
      method: 'GET',
    }).then((response) => {
      const formattedData = response.data.data.map(item => ({
        label: item.mrCategoryName,
        value: item.mrCategoryId
      }));
      setMrcategory(formattedData)
    })
      .catch((error) => {
      })
  };
  const productlist = async (value) => {
    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/api/Product/GetProductList`,
      method: 'GET',
    }).then((response) => {
      const formattedData = response.data.data.map(item => ({
        label: `${item.productName} - ${item.productCode} - ${item.brandName} - ${item.productColour} - ${item.productLength}`,
        value: item.productId
      }));
      setProductList(formattedData)
    })
      .catch((error) => {
      })
  };
  const projectlist = async (value) => {
    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/api/Project/GetProjectList`,
      method: 'GET',
    }).then((response) => {
      const formattedData = response.data.data.map(item => ({
        label: item.projectName,
        value: item.projectId
      }));
      setProjectList(formattedData)
    })
      .catch((error) => {
      })
  };
  const employeelist = async (value) => {
    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/api/Employee/GetEmployeeList`,
      method: 'GET',
    }).then((response) => {
      const formattedData = response.data.data.map(item => ({
        label: item.employeeName,
        value: item.employeeId
      }));
      setEmployeeList(formattedData)
    })
      .catch((error) => {
      })
  };
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([])
 
  const handleAddProduct = (e) => {
    if (selectedProduct) {
      setAddProductList([...addProductList, {
        productName: selectedProduct?.label,
        prodId: selectedProduct?.value,
        unitName: selectedUnits?.label,
        unitid: selectedUnits?.value,
        qty: Qnty,
        prodCost: price,
        requireddate: startDate,
        dId: 0
      }]);
      // Reset the input values
      setSelectedProduct(null);
      setSelectedUnits(null);
      setQnty(0);
      setPrice(0);
    }
  }
  const DecQty = () => {
    if (Qnty > 0) {
      setQnty(Qnty - 1)
    }
  }
  const AddQty = () => {
    setQnty(Qnty + 1)
  }
  const AddPrice = (e) => {
    setPrice(e.target.value)
  }
  const AddMR = (isNewVersion) => {
    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/api/MR/UpdateMR`,
      method: 'POST',
      data: {
        userId: "string",
        offset: 0,
        recordCount: 0,
        mrDate: startDate1,
        mrNumber: mrnum,
        mrCity: mrcity,
        mrProjectId: selectedProject?.value,
        mrCategoryId: selectedMrcategory?.value,
        mrEmployeeId: selectedEmployee?.value,
        MrInitId: selectedMRi?.value,
        mrId: parseInt(location.state.id),
        MRInitId: selectedMRi?.value,
        IsNewVersion: isNewVersion,
        mrStatus: 0,
        mrPriority: selectedPrio?.value,
        mR_details: addProductList
      },
    }
    ).then((response) => {
      if(response?.data?.status === 1)
      {
        toast.success(response.data.message)
        navigate('/MR-list')
      }
      else
      {
        toast.error(response.data.message) 
      }
    })
      .catch((error) => {
        toast.error("something went wrong")
        console.log(error);
      })
  }
  const [inde, setInde] = useState(0)
  const editfunction = ({ contact, index }) => {
    setInde(parseInt(index))
    setSelectedProduct({
      label: contact?.productName,
      value: contact?.prodId,
    })
    setSelectedUnits({
      label: contact?.unitName,
      value: contact?.unitid,
    })
    setQnty(contact?.qty)
    setStartDate(new Date(contact.requireddate ? contact.requireddate?.toISOString().split('T')[0] : contact.requiredDate ? new Date(contact.requiredDate)?.toISOString().split('T')[0] : new Date()?.toISOString().split('T')[0]))
    setPrice(contact?.prodCost)
  }
  const EditProduct = () => {
    let indexToEdit = inde;
    if (indexToEdit >= 0 && indexToEdit < addProductList.length) {
      // Create a copy of the array to avoid mutating state directly
      const updatedProductList = [...addProductList];

      // Update the properties of the object at the specified index
      updatedProductList[indexToEdit] = {
        ...updatedProductList[indexToEdit],
        productName: selectedProduct?.label,
        prodId: selectedProduct?.value,
        unitName: selectedUnits?.value,
        unitid: selectedUnits?.value,
        qty: Qnty,
        prodCost: price,
        startDate: startDate,
      };

      // Update the state with the new array
      setAddProductList(updatedProductList);
    } else {
      console.log("Index out of range");
    }
  };
  const deletefunction = (id) => {
    let indexToDelete = id;

    if (indexToDelete >= 0 && indexToDelete < addProductList.length) {
      // Create a new array without the element at the specified index
      const updatedProductList = [...addProductList.slice(0, indexToDelete), ...addProductList.slice(indexToDelete + 1)];

      // Update the state with the new array
      setAddProductList(updatedProductList);
    } else {
      console.log("Index out of range");
    }
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row" >
          <div className="col-xxl-12">
            <div className="card custom-card" id="cart-container-delete">
              <div className="card-header">
                <div className="card-title">
                  Edit MR
                </div>
              </div>
              <div className="pt-3">
                <Row>
                  <Form.Group as={Col} md="4" controlId="validationCustom01">
                    <Form.Label>Project</Form.Label>
                    <Select
                      value={selectedProject}
                      onChange={handleSelectproject}
                      options={projectList}
                      isSearchable
                      placeholder="Search Project"
                    />
                    <p className="errorMsg pl-3 mt-2">{errors.designationName ? errors.designationName.message : ''}</p>
                  </Form.Group>
                  <Form.Group as={Col} md="4" controlId="validationCustom02">
                    <Form.Label>MRInit Ref</Form.Label>
                    <Select
                      value={selectedMRi}
                      onChange={handleSelectMRi}
                      options={mrireflist}
                      isSearchable
                      placeholder="Search MR Init Ref"
                    />
                    <p className="errorMsg pl-3 mt-2">{errors.designationName ? errors.designationName.message : ''}</p>
                  </Form.Group>
                  <Form.Group as={Col} md="4" controlId="validationCustom02">
                    <Form.Label>Category</Form.Label>
                    <Select
                      value={selectedMrcategory}
                      onChange={handleSelectMr}
                      options={mrcategory}
                      isSearchable
                      placeholder="Search Category"
                    />
                    <p className="errorMsg pl-3 mt-2">{errors.designationName ? errors.designationName.message : ''}</p>
                  </Form.Group>
                </Row>
              </div>
              <div className="pt-3">
                <Row>
                  <Form.Group as={Col} md="4" controlId="validationCustom01">
                    <Form.Label>MR Number</Form.Label>
                    <div style={{ border: '1px solid #f3f3f3', width: '100%', padding: 8 }}>
                      <input type="text" placeholder="Mr number" name="mrNumber" value={mrnum} onChange={mrNumber} />
                    </div>
                  </Form.Group>
                  <Form.Group as={Col} md="4" controlId="validationCustom02">
                    <Form.Label>MR Date</Form.Label>
                    <div style={{ border: '1px solid #f3f3f3', width: '100%', padding: 8 }}>
                      <DatePicker selected={startDate1} onChange={(date) => setStartDate1(date)} />
                    </div>
                  </Form.Group>
                  <Form.Group as={Col} md="4" controlId="validationCustom02">
                    <Form.Label>Priority</Form.Label>
                    <Select
                      value={selectedPrio}
                      onChange={handleSelectPrio}
                      options={[{
                        label: 'major',
                        value: 0
                      }, {
                        label: 'minor',
                        value: 1
                      }]}
                      isSearchable
                      placeholder="Search Priority"
                    />
                    <p className="errorMsg pl-3 mt-2">{errors.designationName ? errors.designationName.message : ''}</p>
                  </Form.Group>
                </Row>
              </div>
              <div className="row">
                <div className="table-responsive col-12">
                  <table className="gridjs-table mt-3">
                    <thead>
                      <tr>
                        <th className='gridjs-th'>S NO</th>
                        <th className='gridjs-th'>Product</th>
                        <th className='gridjs-th'>Unit</th>
                        <th className='gridjs-th'>Quantity</th>
                        <th className='gridjs-th'>Price</th>
                        <th className='gridjs-th'>Date</th>
                        <th className='gridjs-th'>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {addProductList?.map((contact, index) => {
                        return (
                          <tr key={index}>
                            <td className="gridjs-td">{index + 1}</td>
                            <td className="gridjs-td">{contact.productName}</td>
                            <td className="gridjs-td">{contact.unitName}</td>
                            <td className="gridjs-td">{contact.qty}</td>
                            <td className="gridjs-td">{contact.prodCost ? contact.prodCost : contact.prodCost}</td>
                            <td className="gridjs-td">{contact.requireddate ? contact.requireddate?.toISOString().split('T')[0] : contact.requiredDate ? new Date(contact.requiredDate)?.toISOString().split('T')[0] : new Date()?.toISOString().split('T')[0]}</td>
                            <td className="gridjs-td">
                              <div className="hstack gap-2 fs-15">
                                <a className="btn btn-icon btn-sm btn-light" data-bs-toggle="modal" data-bs-target="#exampleEdit" style={{ marginLeft: 10, marginRight: 10 }} onClick={() => editfunction({ contact, index })} ><img src={edit} /></a>
                                <a className="btn btn-icon btn-sm btn-light" style={{ marginLeft: 10, marginRight: 10 }} onClick={() => deletefunction(index)}><img src={del} /></a>
                              </div>
                            </td>
                          </tr>
                        )
                      })}

                    </tbody>
                  </table>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15, marginBottom: 15 }}>
                    <img src={plus} style={{ width: 50, height: 50 }} data-bs-toggle="modal" data-bs-target="#exampleModal" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-4"></div>
                <div className="col-4 p-5">
                  <Button className="mt-3" style={{ width: '100%' }} onClick={() => AddMR(0)}>Save</Button>
                  <Button className="mt-3" style={{ width: '100%' }} onClick={() => AddMR(1)}>Save as New Version</Button>
                </div>
                <div className="col-4"></div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Add Product</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div style={{ padding: 10 }}>
                <label>Product</label>
                <Select
                  value={selectedProduct}
                  onChange={handleSelectProduct}
                  options={productList}
                  isSearchable
                  placeholder="Search Product"
                />
              </div>
              <div className="mt-1">

              </div>
              <div className="row mt-1">
                <div className="col-6">
                  <label>Unit</label>
                  <Select
                    value={selectedUnits}
                    onChange={handleSelectUnits}
                    options={units}
                    isSearchable
                    placeholder="Search Units"
                  />
                </div>
                <div className="col-6">
                  <label>Date</label>
                  <div style={{ border: '1px solid #f3f3f3', width: '100%', padding: 8 }}>
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                  </div>
                </div>
              </div>
              <div className="row mt-1">
                <div className="col-6">
                  <label>Quantity</label>
                  <div className="input-group rounded flex-nowrap">
                    <button className="btn btn-icon btn-light input-group-text flex-fill product-quantity-minus border-end-0" type="button" onClick={DecQty}>-</button>
                    <input type="text" className="form-control form-control-sm text-center w-100" aria-label="quantity" id="product-quantity4" value={Qnty} />
                    <button className="btn btn-icon btn-light input-group-text flex-fill product-quantity-plus border-start-0" type="button" onClick={AddQty}>+</button>
                  </div>
                </div>
                <div className="col-6">
                  <label>Price</label>
                  <div className="input-group rounded flex-nowrap">
                    <input type="text" readOnly className="form-control form-control-sm text-center w-100 p-3" value={price} aria-label="quantity" id="product-quantity4" onChange={AddPrice} />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" data-bs-dismiss="modal" aria-label="Close" onClick={handleAddProduct}>Add</button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="exampleEdit" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Edit Product</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div style={{ padding: 10 }}>
                <label>Product</label>
                <Select
                  value={selectedProduct}
                  onChange={handleSelectProduct}
                  options={productList}
                  isSearchable
                  placeholder="Search Product"
                />
              </div>
              <div className="mt-1">

              </div>
              <div className="row mt-1">
                <div className="col-6">
                  <label>Unit</label>
                  <Select
                    value={selectedUnits}
                    onChange={handleSelectUnits}
                    options={units}
                    isSearchable
                    placeholder="Search Units"
                  />
                </div>
                <div className="col-6">
                  <label>Date</label>
                  <div style={{ border: '1px solid #f3f3f3', width: '100%', padding: 8 }}>
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                  </div>
                </div>
              </div>
              <div className="row mt-1">
                <div className="col-6">
                  <label>Quantity</label>
                  <div className="input-group rounded flex-nowrap">
                    <button className="btn btn-icon btn-light input-group-text flex-fill product-quantity-minus border-end-0" type="button" onClick={DecQty}>-</button>
                    <input type="text" className="form-control form-control-sm text-center w-100" aria-label="quantity" id="product-quantity4" value={Qnty} />
                    <button className="btn btn-icon btn-light input-group-text flex-fill product-quantity-plus border-start-0" type="button" onClick={AddQty}>+</button>
                  </div>
                </div>
                <div className="col-6">
                  <label>Price</label>
                  <div className="input-group rounded flex-nowrap">
                    <input type="text" readOnly className="form-control form-control-sm text-center w-100 p-3" value={price} aria-label="quantity" id="product-quantity4" onChange={AddPrice} />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" data-bs-dismiss="modal" aria-label="Close" onClick={EditProduct}>Edit</button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export default EditMr
