import React from "react";
import { createRoot } from "react-dom/client"; 
import App from "./App";
import axios from "axios";
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

axios.interceptors.request.use(
  (config) => {
    const noAccesstoken = config?.noAccesstoken;
    const accessToken = localStorage.getItem("accessToken");
    //console.log(accessToken);
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    } else {
      if (!noAccesstoken) {
        window.location.href = "/";
      }
    }
    return config;
  },

  (error) => {
    Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (response) => {
   // console.log(response);
    return response;
  },
  async (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        localStorage.clear();
        window.location = "/";
      }
    }
    return Promise.reject(error);
  }
);

root.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>
);