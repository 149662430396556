import React, { FC, Fragment, useState, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Button, InputGroup, Form } from 'react-bootstrap';
import 'filepond/dist/filepond.min.css';
import "react-multiple-select-dropdown-lite/dist/index.css";
import axios from 'axios';
import Loader from '../../utils/Loader';
import { useNavigate } from 'react-router-dom';
import { Oval } from "react-loader-spinner";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Table } from 'react-bootstrap';
import { toast } from "react-toastify";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import search from "../../assets/images/search.png";
import edit from "../../assets/images/edit.svg";
import del from "../../assets/images/trash.svg";
import apprv from "../../assets/images/approval.png";
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

function MRInitlist() {
    const navigate = useNavigate()
  const schema = yup.object().shape({
  })
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const pageSize = 20;
  useEffect(() => {
    onSubmit()
  }, [])

  const onSubmit = async (value) => {
    setLoading(true)
    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/api/MRInit/GetMRInitlist?mriNumber=${value?.mrNumber ? value?.mrNumber : ''}&Offset=${value?.offNum ? value?.offNum : 0}&RecordCount=${pageSize}`,
      method: 'GET',
    }).then((response) => {
      setLoading(false)
      setData(response.data.data)
      setTotalPages(response.data.totalRecords);
    })
      .catch((error) => {
        setLoading(false)
      })
  };
  const changePage = (value) => {
    let temp = (value - 1) * 20;
    setPagination(temp);
    onSubmit({ offNum: temp });
  };
  const ceil = Math.ceil(totalPages / pageSize);
  const addmore = () => {
    navigate('/MRInit')
  }
  const editfunction = (data) => {
    navigate('/edit-mrinit', { state: { id: data } });
  }
  
  const handleKeyDown = (e) => {
    // Check if the pressed key is "Enter"
    if (e.key === 'Enter') {
      // Call your submit function here
      onSubmit({ mrNumber: e.target.value });
    }
  };
  
  return (
    <>
      {/* <Loader loading={loading} /> */}
      <div class="col-xl-12">
        <div class="card custom-card">
          <div class="card-header justify-content-between">
            <div class="card-title">
              MR Init List
            </div>
            <div class="prism-toggle">
              <button class="btn btn-sm btn-primary-light" type='button' onClick={addmore}>Add New<i class="ri-code-line ms-2 d-inline-block align-middle"></i></button>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <div class="gridjs-search mt-1 mb-2">
                <input type="search" placeholder="Type a keyword and press enter" name='mrNumber' onKeyDown={handleKeyDown}  {...register("mrNumber")} aria-label="Type a keyword..." class="gridjs-input gridjs-search-input" />
              </div>
              {loading ?
                  <Skeleton count={5} /> : 
              <table class="gridjs-table">
                <thead>
                  <tr>
                    <th className='gridjs-th'>S NO</th>
                    <th className='gridjs-th'>Mr Init Date</th>
                    <th className='gridjs-th'>Mr Init Number</th>
                    <th className='gridjs-th'>Project Name</th>
                    <th className='gridjs-th'>Location Name</th>
                    <th className='gridjs-th'>Employee Name</th>
                    <th className='gridjs-th'>Notes</th>
                  
                    <th className='gridjs-th'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((contact, index) => {
                    return (
                      <tr key={index}>
                        <td class="gridjs-td">{index + 1}</td>
                        <td class="gridjs-td">{contact.mriDate?.split("T")[0]}</td>
                        <td class="gridjs-td">{contact.mriNumber}</td>
                        <td class="gridjs-td">{contact.projectName}</td>
                        <td class="gridjs-td">{contact.locationName}</td>
                        <td class="gridjs-td">{contact.employeeName}</td>
                        <td class="gridjs-td">{contact.notes}</td>
                  
                        <td class="gridjs-td">
                          <div class="hstack gap-2 fs-15">
                            <a class="btn btn-icon btn-sm btn-light" data-tooltip-id="Edit" data-tooltip-content="Edit MR" style={{ marginLeft: 10, marginRight: 10 }} onClick={() => editfunction(contact?.mriId)} ><img src={edit} /></a>
                          </div>
                        </td>
                      </tr>
                    )
                  })}

                </tbody>
              </table>}
              <div class="gridjs-footer"><div class="gridjs-pagination">
                <div class="gridjs-pages">
                  <ResponsivePagination
                   current={pagination}
                   total={ceil}
                   onPageChange={(value) => changePage(value)}
                  />
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Tooltip id="Edit" />
      <Tooltip id="Delete" />
      <Tooltip id="Approve" />
    </>
  )
}

export default MRInitlist
