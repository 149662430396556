import React, { FC, Fragment, useState, useEffect } from 'react';
import { Button, Card, Col, InputGroup, Row, Form, Alert, Nav, Tab } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useSearchParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import edit from "../../assets/images/edit.svg";
import del from "../../assets/images/trash.svg";
import plus from "../../assets/images/plus.png";
import PerfectScrollbar from "react-perfect-scrollbar";

function AddPO() {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const schema = yup.object().shape({
  })
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  useEffect(() => {
    units()
    Supplierlist()
    projectlist()
    productslist()
    CategoryList()
    Brandlist()
  }, [])
  const [msg1, setMsg1] = useState();
  const [msg2, setMsg2] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [unitlist, setUnits] = useState([])
  const [prolist, setPro] = useState([])
  const [projectlists, setProjectList] = useState([])
  const [selectedsupplier, setSelectedSupplier] = useState([])
  const [productprice, setProductPrice] = useState([])
  const [selectedprojects, setSelectedProjects] = useState([])
  const [selectunitU, setSelectunitU] = useState([])
  const [supplierlist, setSupplierList] = useState([])
  const [selectunitPro, setSelectunitPro] = useState([])
  const [selectunitqty, setSelectunitQty] = useState('')
  const [paymentTerms, setPaymentTerms] = useState('')
  const [deliveryTerms, setDeliveryTerms] = useState('')
  const [notes, setNotes] = useState('')

  const [productlistUnits, setAddProductList] = useState([])
  const [MRNo, setMRNo] = useState('')
  const [ProductCode, setProductCode] = useState('')
  const [ProductName, setProductName] = useState('')
  const [catlist, setCatlist] = useState([])
  const [selectedCat, setSelectedCat] = useState([])
  const [brandlist, setBrandlist] = useState([])
  const [selectedBrand, setSelectedBrand] = useState([])
  const CategoryList = async (value) => {
    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/api/ProductCategory/GetProductCategoryList`,
      method: 'GET',
    }).then((response) => {
      const formattedData = response.data.data.map(item => ({
        label: item.categoryName,
        value: item.categoryId
      }));
      setCatlist(formattedData)
    })
      .catch((error) => {
      })
  };
  const Brandlist = async (value) => {
    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/api/ProductBrand/GetProductBrandList`,
      method: 'GET',
    }).then((response) => {
      const formattedData = response.data.data.map(item => ({
        label: item.brandName,
        value: item.brandId
      }));
      setBrandlist(formattedData)
    })
      .catch((error) => {
      })
  };
  const units = async (value) => {
    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/api/Unit/GetUnitList`,
      method: 'GET',
    }).then((response) => {
      const formattedData = response.data.data.map(item => ({
        label: item.unitCode,
        value: item.unitId
      }));
      setUnits(formattedData)
    })
      .catch((error) => {
      })
  };
  const productslist = async (value) => {
    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/api/Product/GetMRProductSearch?MRNo=${MRNo ? MRNo : ''}&CategoryId=${selectedCat?.value ? selectedCat?.value : 2}&BrandId=${selectedBrand?.value ? selectedBrand?.value : 0}&ProductCode=${ProductCode ? ProductCode : ''}&ProductName=${ProductName ? ProductName : ''}`,
      method: 'GET',
    }).then((response) => {
      setPro(response.data.data)
    })
      .catch((error) => {
      })
  };
  const Supplierlist = async (value) => {
    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/api/Supplier/GetSupplierList`,
      method: 'GET',
    }).then((response) => {
      const formattedData = response.data.data.map(item => ({
        label: item.supName,
        value: item.supplierId
      }));
      setSupplierList(formattedData)
    })
      .catch((error) => {
      })
  };
  const projectlist = async (value) => {
    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/api/Project/GetProjectList`,
      method: 'GET',
    }).then((response) => {
      const formattedData = response.data.data.map(item => ({
        label: item.projectName,
        value: item.projectId
      }));
      setProjectList(formattedData)
    })
      .catch((error) => {
      })
  };
  const onSubmit = async (data) => {
    const newArray = productlistUnits.map(item => ({
      podId: 0,
      poId: 0,
      prodId: item?.productId,
      qty: item?.mrQty,
      prodprice: item?.mrPrice,
      unitid: item?.unitId,
      mrid: item?.mrId,
      currcode: item?.mrCurrency
    }));
    const upload = {
      userId: "string",
      poDate: startDate,
      poNumber: data?.poNumber,
      paymentTerms: data?.paymentTerms,
      deliveryTerms: data?.deliveryTerms,
      notes: data?.notes,
      poSupplierId: selectedsupplier?.value,
      poProjectId: selectedprojects?.value,
      poTotal: data?.poTotal,
      pO_details: newArray
    }
    setLoading(true)
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/PO/CreatePO`, upload);
      if (response?.data?.status === 1) {
        setLoading(false)
        toast.success(response?.data?.message)
        navigate('/PO-list')
      }
      else {
        setLoading(false)
        toast.error(response?.data?.message)
      }

    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  }
  const handleSelectCat = (selectedOption) => {
    setSelectedCat(selectedOption)
    productslist(selectedOption.value)
  }
  const handleSelectBrand = (selectedOption) => {
    setSelectedBrand(selectedOption)
    productslist(selectedOption.value)
  }
  const handleSelectSupplier = (selectedOption) => {
    setSelectedSupplier(selectedOption);
  };
  const handleSelectProject = (selectedOption) => {
    setSelectedProjects(selectedOption);
  };
  const handleUnitProduct = (selectedOption) => {
    setSelectunitPro(selectedOption)
  }
  const handleUnitUnit = (selectedOption) => {
    setSelectunitU(selectedOption)
  }
  const handleAddProduct = (e) => {
    const newData = selectedRows.map(row => row.rowData);
    setAddProductList([...productlistUnits, ...newData]);
    setMRNo('')
    setProductCode('')
    setProductName('')
    setSelectedCat('')
    setSelectedBrand('')

  }
  const deletefunction = (id) => {
    let indexToDelete = id;

    if (indexToDelete >= 0 && indexToDelete < productlistUnits.length) {
      // Create a new array without the element at the specified index
      const updatedProductList = [...productlistUnits.slice(0, indexToDelete), ...productlistUnits.slice(indexToDelete + 1)];

      // Update the state with the new array
      setAddProductList(updatedProductList);
    } else {
      console.log("Index out of range");
    }
  };
  const [selectedRows, setSelectedRows] = useState([]);

  const toggleRow = (index, rowData) => {
    const isSelected = selectedRows.some((row) => row.index === index);

    if (isSelected) {
      setSelectedRows(selectedRows.filter((row) => row.index !== index));
    } else {
      setSelectedRows([...selectedRows, { index, rowData }]);
    }
  };
  const addMrno = (e) => {
    setMRNo(e.target.value)
    if (e.key === 'Enter') {
      productslist(e.target.value)
    }
  }
  const addProductCode = (e) => {
    setProductCode(e.target.value)
    productslist(e.target.value)
  }
  const addProductName = (e) => {
    setProductName(e.target.value)
    productslist(e.target.value)
  }
  console.log(productlistUnits, 'productlistUnits');
  return (
    <>
      <div className="container-fluid">
        <div className="row" >
          <div className="col-xxl-12">
            <div className="card custom-card" id="cart-container-delete">
              <div className="card-header">
                <div className="card-title">
                  Add PO
                </div>
              </div>
              <Form onSubmit={handleSubmit(onSubmit)} className='mt-5'>
                <Row>
                  <Form.Group as={Col} md="3" controlId="validationCustom01">
                    <Form.Label>PO Number</Form.Label>
                    <Form.Control
                      type="text"
                      name='poNumber'
                      placeholder="PO Number"
                      {...register("poNumber")}
                    />
                    <p className="errorMsg pl-3 mt-2">{errors.poNumber ? errors.poNumber.message : ''}</p>
                  </Form.Group>
                  <Form.Group as={Col} md="3" controlId="validationCustom02">
                    <Form.Label>PO Date</Form.Label>
                    <div style={{ border: '1px solid #f3f3f3', width: '100%', padding: 8 }}>
                      <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                    </div>
                  </Form.Group>
                  <Form.Group as={Col} md="3" controlId="validationCustom01">
                    <Form.Label>Supplier</Form.Label>
                    <Select
                      value={selectedsupplier}
                      onChange={handleSelectSupplier}
                      options={supplierlist}
                      isSearchable
                      placeholder="Search Supplier"
                    />
                    <p className="errorMsg pl-3 mt-2">{msg1}</p>
                  </Form.Group>
                  <Form.Group as={Col} md="3" controlId="validationCustom01">
                    <Form.Label>Project</Form.Label>
                    <Select
                      value={selectedprojects}
                      onChange={handleSelectProject}
                      options={projectlists}
                      isSearchable
                      placeholder="Search Projects"
                    />
                    <p className="errorMsg pl-3 mt-2">{msg2}</p>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>Payment Terms</Form.Label>
                    <Form.Control
                      type="text"
                      name='paymentTerms'
                      placeholder="Payment Terms"
                      {...register("paymentTerms")}
                    />
                    <p className="errorMsg pl-3 mt-2">{msg1}</p>
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>Delivery Terms</Form.Label>
                    <Form.Control
                      type="text"
                      name='deiveryTerms'
                      placeholder="Delivery Terms"
                      {...register("deliveryTerms")}
                    />
                    <p className="errorMsg pl-3 mt-2">{msg1}</p>
                  </Form.Group>
                </Row>
                <Row> <Form.Group as={Col} md="12" controlId="validationCustom01">
                    <Form.Label>Notes</Form.Label>
                    <Form.Control
                      type="text"
                      name='notes'
                      placeholder="Notes"
                      {...register("notes")}
                    />
                    <p className="errorMsg pl-3 mt-2">{msg1}</p>
                  </Form.Group>
                </Row>
                <div className="row">
                  <div className="table-responsive col-12">
                    <table className="gridjs-table mt-3">
                      <thead>
                        <tr>
                          <th className='gridjs-th text_row'>Product Code</th>
                          <th className='gridjs-th text_row'>Product Name </th>
                          <th className='gridjs-th text_row'>Unit</th>
                          <th className='gridjs-th text_row'>Brand</th>
                          <th className='gridjs-th text_row'>Category</th>
                          <th className='gridjs-th th_width_min number_row'>Qty</th>
                          <th className='gridjs-th price_row th_width_min'>Price</th>
                          <th className='gridjs-th number_row'>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {productlistUnits?.map((contact, index) => {
                          return (
                            <tr key={index}>
                              <td className="gridjs-td text_row">{contact.productCode}</td>
                              <td className="gridjs-td text_row">{contact.productName}</td>
                              <td className="gridjs-td text_row">{contact.unitName}</td>
                              <td className="gridjs-td text_row">{contact.brandName}</td>
                              <td className="gridjs-td text_row">{contact.categoryName}</td>
                              <td className="gridjs-td price_row">
                                <input className="th_width_min number_row"
                                  type="number"
                                  value={contact.mrQty}
                                  onChange={(e) => {
                                    const newValue = e.target.value;
                                    setAddProductList(prevState => {
                                      const updatedProductlistUnits = prevState.map((item, idx) => {
                                        if (idx === index) {
                                          return { ...item, mrQty: newValue };
                                        }
                                        return item;
                                      });
                                      return updatedProductlistUnits;
                                    });
                                  }}
                                />
                              </td>
                              <td className="gridjs-td">{contact.mrPrice}</td>
                              <td className="gridjs-td">
                                <div className="hstack gap-2 fs-15">
                                  <a className="btn btn-icon btn-sm btn-light" style={{ marginLeft: 10, marginRight: 10 }} onClick={() => deletefunction(index)}><img src={del} /></a>
                                </div>
                              </td>
                            </tr>
                          )
                        })}

                      </tbody>
                    </table>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15, marginBottom: 15 }}>
                      <img src={plus} style={{ width: 50, height: 50 }} data-bs-toggle="modal" data-bs-target="#exampleModal" />
                    </div>
                  </div>
                </div>


                <div className="d-flex justify-content-center formfilter mt-5 mb-5">
                  <Button type="submit" className="mx-2">{loading ?
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "25px" }}>
                      <Oval

                        heigth="20"
                        width="20"
                        color='#12C412'
                        arialLabel='loading'
                      />
                    </div> : "Submit"}</Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content" style={{ width: 900 }}>
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Add Product</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="row mt-1">
                <div className="col-12">
                  <div className='row'>
                    <div className='col-4'>
                      <Form.Control
                        type="text"
                        name='MRNo'
                        value={MRNo}
                        placeholder="MRNo"
                        onChange={(e) => setMRNo(e.target.value)}
                        onKeyUp={addMrno}
                      />
                    </div>
                    <div className='col-4'>
                      <Form.Control
                        type="text"
                        name='productCode'
                        value={ProductCode}
                        placeholder="productCode"
                        onChange={(e) => setProductCode(e.target.value)}
                        onKeyUp={addProductCode}
                      />
                    </div>
                    <div className='col-4'>
                      <Form.Control
                        type="text"
                        name='productName'
                        value={ProductName}
                        placeholder="productName"
                        onChange={(e) => setProductName(e.target.value)}
                        onKeyUp={addProductName}
                      />
                    </div>
                  </div>
                  <div className='row mt-2'>
                    <div className='col-6'>
                      <Select
                        value={selectedCat}
                        onChange={handleSelectCat}
                        options={catlist}
                        isSearchable
                        placeholder="Search Categories"
                      />
                    </div>
                    <div className='col-6'>
                      <Select
                        value={selectedBrand}
                        onChange={handleSelectBrand}
                        options={brandlist}
                        isSearchable
                        placeholder="Search Brand"
                      />
                    </div>
                  </div>
                </div>
                <PerfectScrollbar>
                  <table className="gridjs-table mt-3">
                    <thead>
                      <tr>
                        <th className='gridjs-th'>Select</th>
                        <th className='gridjs-th'>Product</th>
                        <th className='gridjs-th'>MR Nuber</th>
                        <th className='gridjs-th'>Brand</th>
                        <th className='gridjs-th'>Category</th>
                      </tr>
                    </thead>
                    <tbody>
                      {prolist?.map((contact, index) => {
                        const isSelected = selectedRows.some((row) => row.index === index);
                        return (
                          <tr key={index}>
                            <td className="gridjs-td">
                              <input
                                type="checkbox"
                                checked={isSelected}
                                onChange={() => toggleRow(index, contact)}
                              />
                            </td>
                            <td className="gridjs-td">{contact.productName}</td>
                            <td className="gridjs-td">{contact.mrNumber}</td>
                            <td className="gridjs-td">{contact.brandName}</td>
                            <td className="gridjs-td">{contact.categoryName}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </PerfectScrollbar>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" data-bs-dismiss="modal" aria-label="Close" onClick={handleAddProduct}>Add</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddPO
